import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const eskandaricollections = "images/" + this.props.data.image;
    const bio = this.props.data.bio;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={eskandaricollections}
                alt="Eskandari Collections"
              />
            </div>
            <div className="nine columns main-col">
            <h5 className="text-justify">
            Discover the vibrant world of Eskandari Collections, where two skilled artists bring their creative expressions to life. Our diverse collection showcases captivating artworks, from emotive sculptures to vibrant paintings.
            Skillfully transforming raw materials into expressive forms, the sculptures narrate stories and add a unique charm to your home or office. The paintings, ranging from abstract to realism, infuse your spaces with colorful emotions.
            In a modern twist, Eskandari Collections merges digital and traditional art by creatively reimagining emojis and symbols on high-quality canvas, adding a contemporary touch to your decor.
            Explore our exclusive products, from limited-edition prints to lifestyle accessories, showcasing the unique artistry of Amaneh and Maryam. Whether you're an art connoisseur, an interior design enthusiast, or seeking meaningful gifts, Eskandari Collections offers inspiration for every taste.
            Immerse yourself in the dedication of these talented artists and transform your living and working spaces with exceptional works. Be inspired daily by incorporating their creativity into your environment.
            </h5>
            <p className="text-justify" style={{ marginTop: '20px', fontSize: '16px' }}>
    Explore more on their individual websites:
    {' '}
    <a href="http://www.amaneheskandari.com" style={{ color: '#333', fontWeight: 'bold', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer">Amaneh Eskandari</a>
    {' | '}
    <a href="http://www.maryameskandari.art" style={{ color: '#333', fontWeight: 'bold', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer">Maryam Eskandari</a>
  </p>
</div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
