import React, { useState, useEffect, useCallback } from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import type { Engine } from 'tsparticles-engine';
import { loadImageShape } from "tsparticles-shape-image";
import './App.css';
import particlesOptions from './particles20.json';
import Footer from './Components/Footer';
import About from './Components/About';
import Header from './Components/Header';
import ReactGA from 'react-ga';

const handleOverlayClick = () => {
  window.open('https://example.com', '_blank');
};

const App = () => {
  const [resumeData, setResumeData] = useState({});
  const [isPortfolioMounted, setIsPortfolioMounted] = useState(true);

  const getResumeData = () => {
    $.ajax({
      url: './resumeData.json',
      dataType: 'json',
      cache: false,
      success: (data) => {
        setResumeData(data);
      },
      error: (xhr, status, err) => {
        console.log(err);
        alert(err);
      },
    });
  };

  useEffect(() => {
    getResumeData();
    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);
  }, []);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
    loadImageShape(engine);
  }, []);

  const handlePortfolioMount = () => {
    setIsPortfolioMounted(true);
  };

  const handlePortfolioUnmount = () => {
    setIsPortfolioMounted(false);
  };

  return (
    <div className="App">
      {/* Pass particlesOptions and particlesInit as props */}
      <Header
        data={resumeData.main}
        particlesOptions={particlesOptions}
        particlesInit={particlesInit}
        onMount={handlePortfolioMount}
        onUnmount={handlePortfolioUnmount}
      />
      <About data={resumeData.main} />
      <Footer data={resumeData.main} />
      <div className="overlay" onClick={handleOverlayClick}>
        <div className="overlay-text"></div>
      </div>
      <Particles options={particlesOptions} init={particlesInit} />
    </div>
  );
};

export default App;
